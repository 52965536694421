<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <p class="mt-5 pt-4 text-h6">Bem vindo(a), <span class="font-weight-bold text-capitalize">{{ username }}</span></p>
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <app-bar-user-menu
          :nome="this.username"
          :iniciaisNome="this.iniciais"
          ></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; {{ new Date().getFullYear() }}<a
              href="https://integrasfacil.com.br"
              class="text-decoration-none"
              target="_blank"
            >&nbsp;&nbsp;INTEGRAS FÁCIL LTDA</a></span>
          <span class="d-sm-inline d-none">
            Todos os direitos reservados
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Cookie from 'js-cookie'
import Strings from '@/common/strings'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  mounted(){
    
    let username = Cookie.get(Strings.cookie_username)

    if(username.split(' ').length > 1){

      var sobrenome = ''

      if(username.split(' ')[1].length == 2 || username.split(' ')[1].length == 3 && username.split(' ').length > 2)
        sobrenome = username.split(' ')[2]
      else
        sobrenome = username.split(' ')[1]

      this.username = username.split(' ')[0] + ' ' + sobrenome
      this.iniciais = Array.from(this.username)[0] + Array.from(username.split(' ')[1])[0]
    }
    else{
      this.username = username
      this.iniciais = Array.from(this.username)[0] + Array.from(this.username)[1]
    }
  },
  data(){
    return {
      username: '',
      iniciais: ''
    }
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
