import Cookie from 'js-cookie'
import Strings from '../common/strings'
import { http } from './http'

export default{
    auth(to, from, next){
        const token = Cookie.get(Strings.cookie_token)
        
        if(token){
            let option = {
                headers: {
                  'Authorization': `${Cookie.get(Strings.cookie_token)}`,
                }
            }

            http.post('/Usuario/validar/token', null, option).then(() => {
                next()
            }).catch(function (){
                Cookie.remove(Strings.cookie_token)
                Cookie.remove(Strings.cookie_customer_id)
                next('/auth/login')
            })
        }else{
            next('/auth/login')
        }
    },
}