import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from './default-preset/preset'
import colors from 'vuetify/lib/util/colors'
import strings from '@/common/strings'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      light: {
        primary: strings.color_primary,
        secondary: strings.color_secundary,
        accent: colors.green.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: colors.red,
        secondary: colors.green.darken1,
        accent: colors.green.black,
        error: colors.red.accent3,
      },
    },
  },
})
