<template>
  <div>
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            v-bind="attrs"
            v-on="on"
            color="primary"
          >
            <span class="text-h6 white--text">{{ iniciaisNome }}</span>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pr-3 pt-2 rd-3">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar color="primary" size="40px">
              <span class="text-h6 white--text">{{ iniciaisNome }}</span>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              {{ nome }}
            </span>
            <small class="text--disabled text-capitalize">cliente</small>
          </div>
        </div>

        <v-divider></v-divider>
        <!-- Logout -->
        <v-list-item @click="logout()">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

import Cookie from 'js-cookie'
import Strings from './../../common/strings'

export default {
  props: {
    nome: String,
    iniciaisNome: String
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods:{
    logout(){
        Cookie.remove(Strings.cookie_token)
        Cookie.remove(Strings.cookie_username)
        this.$router.push({name: 'pages-login'})
    }
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
