import Vue from 'vue'
import VueRouter from 'vue-router'
import Guard from '../services/middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'portal',
    beforeEnter: Guard.auth
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import('@/views/portal/Dashboard.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/boletos',
    name: 'resumo-boletos',
    component: () => import('@/views/boletos/Boletos.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/pagamentos',
    name: 'resumo-pagamentos',
    component: () => import('@/views/pagamentos/ResumoPagamentos.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/pdfviewer',
    name: 'pdf-viewer',
    component: () => import('@/views/embed/PdfViewer.vue'),
    beforeEnter: Guard.auth,
    props: true
  },
  {
    path: '/atendimento',
    name: 'resumo-atendimentos',
    component: () => import('@/views/atendimento/Atendimento.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/reportar-bug',
    name: 'reportar-bug',
    component: () => import('@/views/report-bug/ReportBug.vue'),
    beforeEnter: Guard.auth,
  },
  {
    path: '/empreendimento',
    name: 'resumo-empreendimentos',
    component: () => import('@/views/empreendimento/Empreendimento.vue'),
  },
  {
    path: '/empreendimento-card',
    name: 'empreendimento-card',
    component: () => import('@/views/empreendimento/CardEmpreendimentos.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/parcelas',
    name: 'parcelas',
    component: () => import('@/views/parcelas/Parcelas.vue'),
    beforeEnter: Guard.auth,
    props: true
  },
  {
    path: '/extrato',
    name: 'resumo-extrato',
    component: () => import('@/views/extrato/Extrato.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/obras/acompanhamento',
    name: 'obras-acompanhamento',
    component: () => import('@/views/obra/acompanhamento/Acompanhamento.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/anuncios/unidades',
    name: 'anuncios',
    component: () => import('@/views/anuncio/AnuncioUnidade.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/documentos',
    name: 'documentos',
    component: () => import('@/views/documents/Documentos.vue'),
    beforeEnter: Guard.auth,
    props: route => ({ object: route.params.object, obraId: route.params.obraId })
  },
  {
    path: '/meus-dados',
    name: 'meus-dados',
    component: () => import('@/views/config-conta/AccountSettings.vue'),
    beforeEnter: Guard.auth
  },
  {
    path: '/auth/login',
    name: 'pages-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/register',
    name: 'pages-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/changemail',
    name: 'changemail',
    component: () => import('@/views/auth/ChangeMail.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
