import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import router from './router'
import store from './store'
import VueLoading from 'vuejs-loading-plugin'
import VueEllipseProgress from 'vue-ellipse-progress'
import moment from 'moment'
import VueElementLoading from "vue-element-loading"
import { VueMaskDirective } from 'v-mask'

Vue.prototype.moment = moment

Vue.config.productionTip = false

Vue.use(VueEllipseProgress)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Carregando...', // default 'Loading'
  loading: false, // default false
  classes: ['v-card-loader'],
})

Vue.directive('mask', VueMaskDirective)

Vue.component("VueElementLoading", VueElementLoading)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
