<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="setThemeDark()"
    >
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import Strings from '@/common/strings'
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import Cookies from 'js-cookie'

export default {
  methods: {
      setThemeDark(){
        Cookies.remove(Strings.cookie_theme_dark)
        Cookies.set(Strings.cookie_theme_dark, !this.$vuetify.theme.dark, { expires: 360 })
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      }
  },
  data(){
      return{
        themeDark: false
      }
  },
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      }
    }
  },
}
</script>

<style>
</style>
