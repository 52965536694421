<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>
<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import Cookie from 'js-cookie'
import Strings from './common/strings'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  mounted() {
    const setThemeDark = Cookie.get(Strings.cookie_theme_dark) === 'true'
    this.$vuetify.theme.dark = setThemeDark

    const favicon = document.getElementById('favicon')
    favicon.href = Strings.favicon
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
