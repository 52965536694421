const apiUrl = process.env.VUE_APP_API_URL
const cookie_token = '_uau_cook'
const cookie_username = '_uau_cook_username'
const cookie_theme_dark = 'darkTheme'
const payloadEmpreendimentos = 'payloadEmpreendimentos'
const whatsapp = process.env.VUE_APP_ATENDIMENTO_WHATSAPP
const email = process.env.VUE_APP_ATENDIMENTO_EMAIL
const telefone = process.env.VUE_APP_ATENDIMENTO_TELEFONE
const site = process.env.VUE_APP_ATENDIMENTO_SITE
const logo = process.env.VUE_APP_URL_LOGO
const color_primary = process.env.VUE_APP_COLOR_PRIMARY
const color_secundary = process.env.VUE_APP_COLOR_SECONDARY
const favicon = process.env.VUE_APP_URL_FAVICON
const exibir_horario_funcionamento = process.env.VUE_APP_INFORMAR_HORARIO_FUNCIONAMENTO
const imobiliariaId = process.env.VUE_APP_IMOBILIARIA_ID
const imobiliaria = process.env.VUE_APP_INCORPORATION_NAME
const project_id = process.env.VUE_APP_PROJECT_ID
const module_acompanhamento_obra = process.env.VUE_APP_MODULE_ACOMPANHAMENTO_OBRA
const module_anunciar_unidade = process.env.VUE_APP_MODULE_ANUNCIAR_UNIDADE
const colunm_show_value_parcela = process.env.VUE_APP_SHOW_COLUNM_VALUE_PARCELA
const colunm_show_menu_about_us = process.env.VUE_APP_SHOW_MENU_ABOUT_US
const menu_about_us_url = process.env.VUE_APP_URL_ABOUT_US
const show_whatsapp_icon = process.env.VUE_APP_SHOW_WHATSAPP_ICON
// Generic messages

const str_unauthorized = 'Não autorizado'
const str_cpfOrCnpjInvalid = 'CPF ou CPNJ Informado é inválido'

export default{
    apiUrl,
    cookie_token,
    cookie_username,
    str_unauthorized,
    str_cpfOrCnpjInvalid,
    payloadEmpreendimentos,
    cookie_theme_dark,
    whatsapp,
    email,
    telefone,
    site,
    logo,
    color_primary,
    color_secundary,
    favicon,
    exibir_horario_funcionamento,
    imobiliariaId,
    imobiliaria,
    project_id,
    module_acompanhamento_obra,
    module_anunciar_unidade,
    colunm_show_value_parcela,
    colunm_show_menu_about_us,
    menu_about_us_url,
    show_whatsapp_icon
}